// RESPONSIVE MIXINS

$retinaA: "(-webkit-min-device-pixel-ratio: 1.75)";
$retinaB: "(min-resolution: 300dpi)";
$retinaC: "(min-resolution: 1.75dppx)";

$h_800: "(max-height: 800px)";
$h_1536: "(max-height: 1536px)";
$h_2220: "(max-height: 2220px)";

$w_1536: "(max-width: 800px)";
$w_1920: "(max-width: 1920px)";
$w_3000: "(max-width: 3000px)";
$w_3840: "(max-width: 3840px)";

// PORTRAIT
@mixin h_800 {
	@media #{$h_800} {
		@content;
	}
}
@mixin h_1536 {
	@media #{$h_1536},
		#{$h_800} and #{$retinaA},
		#{$h_800} and #{$retinaB},
		#{$h_800} and #{$retinaC} {
		@content;
	}
}
@mixin h_2220 {
	@media #{$h_2220},
		#{$h_1536} and #{$retinaA},
		#{$h_1536} and #{$retinaB},
		#{$h_1536} and #{$retinaC},
		(min-height: 2220px) {
		@content;
	}
}

// LANDSCAPE
@mixin w_1536 {
	@media #{$w_1536} {
		@content;
	}
}
@mixin w_1920 {
	@media #{$w_1920} {
		@content;
	}
}
@mixin w_3000 {
	@media #{$w_3000},
		#{$w_1536} and #{$retinaA},
		#{$w_1536} and #{$retinaB},
		#{$w_1536} and #{$retinaC} {
		@content;
	}
}
@mixin w_3840 {
	@media #{$w_3840},
		#{$w_1920} and #{$retinaA},
		#{$w_1920} and #{$retinaB},
		#{$w_1920} and #{$retinaC},
		(min-width: 3840px) {
		@content;
	}
}
