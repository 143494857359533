@use "responsive";

//
// PORTRAIT MODE
//

@media (orientation: portrait) {
  // General
  main {
    position: absolute;
    // margin-top: 5vh;

    // smartphone first
    width: 100%;
    height: 92%;
    margin-top: 8%;

    justify-content: space-between;
  }

  // Title
  header {
    font-size: calc(3rem + 5vw);
    // font-size: min(19vw, 6rem);
  }

  $titleMargin: 0.1rem;

  header > span {
    &:first-child {
      margin-bottom: $titleMargin;
    }
    &::last-child {
      margin-left: $titleMargin;
    }
  }

  // Nav
  nav {
    order: 1;

    // font-size: min(8vw, 2.4rem);
    font-size: calc(2rem + 2vw);
    text-align: center;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    margin: 0 -0.5rem;
    margin-right: 0.5rem;
    & > * {
      margin: 0 0.5rem;
    }
  }

  nav[arrow] {
    font-size: calc(2rem + 2vw);
  }

  // Content
  section[content] {
    max-width: 85%;
    max-height: 60%;
    // min-height: 60%;

    font-size: 3.5vh;
    overflow: auto;
  }

  section[about] {
    font-size: 3.8vh;
    -webkit-overflow-scrolling: touch;
  }

  div[names] {
    font-size: 4vh;

    & > p:first-child {
      margin-right: 16vh;
    }
  }

  section[release] {
    min-height: 64.5%;
    font-size: 4.5vh;
  }

  // section[release]:not(section[hidden]) {
  // 	width: 100%;
  // 	display: flex;
  // 	flex-direction: column;
  // 	align-items: stretch;
  // }

  section[listen] {
    // width: 75%;
    // margin-top: 75%;
  }

  div[background] {
    background-position: center center;
    &[release] {
      background-position: center center;
    }

    @include responsive.h_2220 {
      background-image: url(../media/cover-pulse-2023.jpg);
    }
    @include responsive.h_1536 {
      background-image: url(../media/cover-pulse-2023.jpg);
    }
    @include responsive.h_800 {
      background-image: url(../media/cover-pulse-2023.jpg);
    }

    &[about] {
      @include responsive.h_2220 {
        background-image: url(../media/backg04.jpg);
      }
      @include responsive.h_1536 {
        background-image: url(../media/backg04.jpg);
      }
      @include responsive.h_800 {
        background-image: url(../media/backg04.jpg);
      }
    }
    &[listen] {
      @include responsive.h_2220 {
        background-image: url(../media/backg03_f_h_2220.jpg);
      }
      @include responsive.h_1536 {
        background-image: url(../media/backg03_f_h_1536.jpg);
      }
      @include responsive.h_800 {
        background-image: url(../media/backg03_f_h_800.jpg);
      }
    }
    &[release] {
      @include responsive.h_2220 {
        // background-image: url(../media/apart3_w_1200.jpg) !important;
      }
      @include responsive.h_1536 {
        // background-image: url(../media/apart3_w_800.jpg) !important;
      }
      @include responsive.h_800 {
        // background-image: url(../media/apart3_w_400.jpg) !important;
      }
    }
  }
}
